import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/SEO'

import PhotoList from '../components/PhotoList'

const PageTitle = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  h1 {
    position: relative;
    text-align: center;
    margin: 0;
    padding: 0 1.5rem;
    color: #fff;
    background-color: var(--primary-color-dark);
  }
`

const Line = styled.div`
  border-bottom: 2px dotted var(--rust);
  width: 100%;
  position: absolute;
  margin-top: 0.4em;
`

const PageDescription = styled.p`
  text-align: center;
  padding: 0 1.5rem;
  max-width: 800px;
  margin: 3rem auto 3rem auto;
`

export default function SingleTagPage({ data: { tag, photos } }) {
  return (
    <>
      <SEO title={tag.name} image={tag.cover?.image?.asset?.fluid?.src} />
      <PageTitle>
        <Line />
        <h1>{tag.name}</h1>
      </PageTitle>
      {tag.description && <PageDescription>{tag.description}</PageDescription>}
      <PhotoList photos={photos.nodes} />
    </>
  )
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    tag: sanityTag(slug: { current: { eq: $slug } }) {
      name
      description
    }
    photos: allSanityPhoto(
      filter: { tags: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        id
        name
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        date
        category {
          title
        }
        project {
          name
          slug {
            current
          }
        }
        tags {
          name
          slug {
            current
          }
        }
      }
    }
  }
`
